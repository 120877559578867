<template>
<div class="booking-hotel">
    <div style="margin-top: 3px; height: 310px">
        <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" />

        <DataTable
            :value="hotelBooking"
            responsiveLayout="scroll"
            scrollHeight="280px"
            v-model:selection="selectedHotelBooking" 
            selectionMode="single"
            dataKey="id"
            v-if="!loadingError.isError"
        >
            <!-- <Column field="id" header="ID" :style="{'min-width':'30px'}"></Column> -->
            <Column field="hotel_name" header="Hotel" :style="{'min-width':'190px'}"></Column>
            <Column field="province" header="City" :style="{'min-width':'100px'}"></Column>
            <Column field="room_type" header="Rm. Type" :style="{'min-width':'90px'}"></Column>
            <Column field="sgl" header="SGL" :style="{'min-width':'40px'}"></Column>
            <Column field="twn" header="TWN" :style="{'min-width':'40px'}"></Column>
            <Column field="dbl" header="DBL" :style="{'min-width':'40px'}"></Column>
            <Column field="trp" header="TRL" :style="{'min-width':'40px'}"></Column>
            <Column field="extra_bed" header="Extra bed" :style="{'min-width':'80px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'75px', 'width':'75px', 'padding':'0.4rem 0rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button v-if="can('update', 'booking')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="onEditHotelBooking(slotProps.data)" />
                    <Button v-if="can('delete', 'booking')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteHotelBooking(slotProps.data)" />
                </template>
            </Column>
            <template #footer v-if="selectedHotelBooking && selectedHotelBooking !== null">
                From: {{ dayjs(selectedHotelBooking.from_date).format('DD-MMM-YYYY') }}; &nbsp; To: {{ dayjs(selectedHotelBooking.to_date).format('DD-MMM-YYYY') }}; &nbsp; <br />
                Total room: {{ selectedHotelBooking.sgl + selectedHotelBooking.twn + selectedHotelBooking.dbl + selectedHotelBooking.trp }}
            </template>
        </DataTable>
    </div>

    <hr style="margin-top: 25px; border: 0; border-top: 1px solid var(--surface-d);"/>
    <div style="display: flex; align-self: flex-end; margin-bottom: 10px">
        <Button v-if="can('create', 'booking')" label="Add Hotel Booking" class="p-button p-button-sm" @click="openBookingHotelForm()" :disabled="bookingID == 0 || loadingError.isError" />
        <!-- <Dialog v-model:visible="isBookingHotelFormShow" header="Hotel Booking" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :modal="true"> -->
        <Dialog v-model:visible="isBookingHotelFormShow" header="Hotel Booking" :style="{width: '53rem', margin: '0px 5px'}" :modal="true">
            <BookingHotelForm ref="BookingHotelFormRef" :BookingID="bookingID" @RefreshHotelBooking="getHotelBooking" />
        </Dialog>

        <Button icon="pi pi-print" label="Voucher" class="p-button-secondary p-button-sm p-ml-2" @click="printHotelVoucher()" :disabled="bookingID == 0 || loadingError.isError || !selectedHotelBooking" />
    </div>
</div>
</template>

<script>
import { ref, onMounted, reactive, nextTick } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import BookingService from '../service/BookingService';
import LoadingDataError from '../components/LoadingDataError.vue'
import BookingHotelForm from './BookingHotelForm.vue'
import dayjs from 'dayjs';

export default {
    // props:{
    //     BookingID: Number
    // },
    setup() {
        const { can } = useAbility()
        const confirm = useConfirm();
        const toast = useToast();
        let loadingError = reactive({isError: false, errorCode: ''})
        let hotelBooking = ref([])
        let selectedHotelBooking = ref()
        const isBookingHotelFormShow = ref(false)
        let bookingID = ref(0)
        const BookingHotelFormRef = ref()

        const bookingService = ref(new BookingService())

        onMounted(() => {
            // if(props.BookingID && props.BookingID != 0) {
            //     getHotelBooking(props.BookingID)
            // }
        })

        //================Method================
        const getHotelBooking = (Booking_ID) => {
            if(Booking_ID != 0) {
                //SEND BOOKING_ID TO HOTEL BOOKING FORM'S PROPS
                bookingID.value = Booking_ID

                hotelBooking.value.length = 0;
                selectedHotelBooking.value = null

                bookingService.value.getBookingHotel(Booking_ID).then(data => {
                    if(!data.errorResponse) {
                        hotelBooking.value = data

                        loadingError.isError = false
                    } else {
                        loadingError.isError = true
                        loadingError.errorCode = data.status
                    }
                })
            }
        }

        const onEditHotelBooking = (data) => {
            selectedHotelBooking.value = data
            
            isBookingHotelFormShow.value = true
            //WAIT COMPONENT RENDERED THEN SEND SELECTED DATA TO FORM
            nextTick(() => {
                BookingHotelFormRef.value.editHotelBooking(selectedHotelBooking.value)
            })
        }

        const openBookingHotelForm = () => {
            isBookingHotelFormShow.value = true
        }

        const confirmDeleteHotelBooking = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.hotel_name +'" booking?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const bookingHotelID = data.id
                    bookingService.value.deleteBookingHotel(bookingHotelID).then((data) => {
                        if(!data.errorResponse){
                            hotelBooking.value = hotelBooking.value.filter(val => val.id !== bookingHotelID);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 4500});
                        }
                    })
                }
            });
        }

        const printHotelVoucher = () => {
            window.open('/booking/print-hotelvoucher/?bookinghotelid='+selectedHotelBooking.value.id, 'popUpWindow','height=860,width=720,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }

        return {
            can,
            loadingError,
            hotelBooking,
            selectedHotelBooking,
            onEditHotelBooking,
            getHotelBooking,
            confirmDeleteHotelBooking,

            openBookingHotelForm,
            isBookingHotelFormShow,
            bookingID,
            BookingHotelFormRef,

            printHotelVoucher,
            dayjs,
        }
    },
    components: {
        LoadingDataError,
        BookingHotelForm,
    }
}
</script>

<style lang="scss" scoped>

</style>