<template>
<div class="p-fluid" style="margin-top: 10px">
    <div class="p-grid">
        <div class="p-col-6">
            <label for="hotel_name">Hotel name:</label>
            <AutoComplete v-model="selectedHotel" :suggestions="filteredHotels" @complete="searchHotel($event)" @item-select="onHotelNameSelect($event)" field="hotel_name" :class="{'p-invalid':v_bookinghotel$.hotel_id.$error}" />
        </div>
        <div class="p-col-6">
            <label for="room_tpe">Room type:</label>
            <Dropdown v-model="selectedRoomType" :options="hotelRoomType" @change="onRoomTypeChanged()" optionLabel="room_type" :class="{'p-invalid':v_bookinghotel$.roomtype_id.$error}" />
        </div>
    </div>

    <div class="p-grid">
        <div class="p-col-6">
            <label for="book_from">From date:</label>
            <Calendar id="book_from" v-model="hotelBookingForm.from_date" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookinghotel$.from_date.$error || !isBookingDateValid}" />        
        </div>
        <div class="p-col-6">
            <label for="book_to">To date:</label>
            <Calendar id="book_to" v-model="hotelBookingForm.to_date" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookinghotel$.to_date.$error || !isBookingDateValid} " />
        </div>
    </div>

    <div class="p-grid" style="align-items: flex-end;">
        <div class="p-col">
            <label for="sgl">SGL:</label>
            <InputNumber id="sgl" v-model="hotelBookingForm.sgl" />
        </div>
        <div class="p-col">
            <label for="twn">TWN:</label>
            <InputNumber id="twn" v-model="hotelBookingForm.twn" />
        </div>
        <div class="p-col">
            <label for="book_to">DBL:</label>
            <InputNumber id="dbl" v-model="hotelBookingForm.dbl" />
        </div>
        <div class="p-col">
            <label for="trp">TRP:</label>
            <InputNumber id="trp" v-model="hotelBookingForm.trp" />
        </div>
        <div class="p-col">
            <label for="extra_bed">Extra Bed:</label>
            <InputNumber id="extra_bed" v-model="hotelBookingForm.extra_bed" />
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col" style="padding-top: 15px; align-self: flex-end">
            <Button v-if="!isEditHotelBooking" label="Save Hotel Booking" @click="saveHotelBooking()" class="p-button-success p-button-sm" style="width: 140px;"></Button>
            <Button v-if="isEditHotelBooking" label="Update Hotel Booking" @click="updateHotelBooking()" class="p-button-success p-button-sm p-mr-1" style="width: 150px" />
        </div>
    </div>
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import HotelService from '../service/HotelService';
import BookingService from '../service/BookingService';

// const BookingHotel = defineAsyncComponent(() => import(/* webpackChunkName: "bookinghotel" */'./Booking_Hotel.vue'));

export default {
    props: {
        BookingID: Number
    },
    emits: ['RefreshHotelBooking'],
    setup(props, { emit }) {
        const toast = useToast()
        const selectedHotel = ref()
        const filteredHotels = ref()
        let hotelID = ref(0)

        let hotelRoomType = ref([])
        let selectedRoomType = ref()

        let hotelBookingForm = reactive({})
        let isEditHotelBooking = ref(false)
        let isBookingDateValid = ref(true)
        
        const hotelService = ref(new HotelService())
        const bookingService = ref(new BookingService())

        //=================Method=================
        const searchHotel = (event) => {
            setTimeout(() => {
                if (event.query.trim().length) {
                    hotelService.value.searchHotel(event.query.toLowerCase()).then(data => {
                        if(!data.errorResponse) {
                            if(data.length > 0) {
                                filteredHotels.value = data
                            } else {
                                filteredHotels.value = [{hotel_name: 'No result'}]
                                hotelRoomType.value.length = 0
                            }
                        }
                    })
                }
            }, 250);
        }

        const onHotelNameSelect = (event) => {
            if(event.value.id) {
                hotelID.value = event.value.id
                hotelBookingForm.hotel_id = hotelID.value
                getHotelRoomType(hotelID.value)
                hotelBookingForm.roomtype_id = ''
            } else {
                selectedHotel.value = ''
                hotelBookingForm.hotel_id = ''
                hotelBookingForm.roomtype_id = ''
            }
        }

        const getHotelRoomType = (hotel_id) => {
            if (hotel_id != 0) {
                hotelService.value.getRoomType(hotel_id).then(data => {
                    if(!data.errorResponse) {
                        hotelRoomType.value = data
                    }

                    if(hotelBookingForm.roomtype_id != '') {
                        selectedRoomType.value = hotelRoomType.value.find(item => item.id === hotelBookingForm.roomtype_id )
                    }
                })
            }
        }

        const onRoomTypeChanged = () => {
            hotelBookingForm.roomtype_id = selectedRoomType.value.id
        }

        const initHotelBookingForm = () => {
            const hotel_booking_form = {
                id: '',
                roomtype_id: '',
                sgl: null,
                twn: null,
                dbl: null,
                trp: null,
                extra_bed: null,
                from_date: '',
                to_date: '',
                booking_id: '',
                hotel_id: '',
            }

            selectedRoomType.value = ''
            selectedHotel.value = ''

            Object.assign(hotelBookingForm, hotel_booking_form)
        }

        const saveHotelBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {
                hotelBookingForm.booking_id = props.BookingID
                bookingService.value.addBookingHotel(hotelBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Save Error', detail: data.message, life: 4500}); 
                        } else {
                            initHotelBookingForm()
                            filteredHotels.value.length = 0
                            v_bookinghotel$.value.$reset();
                            isBookingDateValid.value = true
                            emit('RefreshHotelBooking', props.BookingID)
                            toast.add({severity:'success', summary: 'Success', detail:'Hotel booking has been added.', life: 3000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        const editHotelBooking = (emitData) => {
            isEditHotelBooking.value = true
            initHotelBookingForm()
            
            Object.keys(hotelBookingForm).forEach((key)=>{
                hotelBookingForm[key] = emitData[key]
            })

            hotelBookingForm.from_date = new Date(hotelBookingForm.from_date)
            hotelBookingForm.to_date = new Date(hotelBookingForm.to_date)
            
            selectedHotel.value = emitData.hotel_name
            getHotelRoomType(emitData.hotel_id)
        }

        const updateHotelBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {                
                bookingService.value.updateBookingHotel(hotelBookingForm.id, hotelBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Update Error', detail: data.message, life: 4500}); 
                        } else {
                            v_bookinghotel$.value.$reset();
                            emit('RefreshHotelBooking', props.BookingID)
                            isBookingDateValid.value = true
                            toast.add({severity:'success', summary: 'Success', detail:'Hotel booking has been updated.', life: 3000});        
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        //=================Form Validation=================
        const bookingHotelRules = computed(() => {
            return {
                hotel_id: { required },
                roomtype_id: { required },
                from_date: { required },
                to_date: { required },
            }
        })
        const v_bookinghotel$ = useVuelidate(bookingHotelRules, hotelBookingForm)
        const validateForm = () => {
            v_bookinghotel$.value.$validate();
            if(!v_bookinghotel$.value.$error){
                return true
            } else {
                return false
            }
        }

        const validateBookingDate = () => {
            const booking_from = new Date(hotelBookingForm.from_date)
            const booking_to = new Date(hotelBookingForm.to_date)
            if(booking_from >= booking_to) {
                isBookingDateValid.value = false
                toast.add({severity:'warn', summary: 'Error', detail: 'Selected date is invalid.', life: 5000}); 
                return false
            } else {
                isBookingDateValid.value = true
                return true
            }
        }

        return {
            selectedHotel,
            filteredHotels,
            searchHotel,
            onHotelNameSelect,
            saveHotelBooking,

            hotelRoomType,
            selectedRoomType,
            onRoomTypeChanged,

            v_bookinghotel$,
            isBookingDateValid,
            hotelBookingForm,
            editHotelBooking,
            isEditHotelBooking,
            updateHotelBooking,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>